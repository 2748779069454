
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.delegations {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__empty {
    background-color: $color-white;
    border-radius: 16px;
    width: 823px;
    padding: 32px;
    position: relative;
    margin-bottom: 223px;

    @include for-size(phone-landscape-down) {
      width: 100%;
      padding: 24px;
    }
    @include for-size(phone-portrait-down) {
      padding: 20px;
      margin-bottom: 180px;
    }
  }

  &__title {
    margin-bottom: 24px;
    font-size: 22px;
    line-height: 24px;
    width: 310px;

    @include for-size(phone-portrait-down) {
      width: 100%;
      margin-bottom: 16px;
      font-size: 20px;
      line-height: 22px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    width: 310px;

    @include for-size(phone-portrait-down) {
      width: 100%;
    }
  }

  &__image {
    width: 251px;
    height: 160px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    @include for-size(phone-portrait-down) {
      display: none;
    }
  }

  &__image-arrow {
    width: 264.44px;
    height: 327.25px;
    position: absolute;
    left: 0;
    bottom: -255px;

    @include for-size(phone-portrait-down) {
      width: 200px;
      height: 267px;
      bottom: -200px;
    }
  }
}
